/**
 * A composable for setting meta tags and link relations for a page.
 * It uses the current route, request URL details, and i18n settings to
 * generate the appropriate meta tags for SEO purposes, such as canonical
 * links and alternate links for all supported locales.
 *
 * @example
 * // In a Vue component
 * <script setup>
 * usePageMeta();
 * </script>
 */
import { storeToRefs } from 'pinia';
import { useGlobalsStore } from '~/stores/globals';

export const usePageMeta = async (title: string, description: string) => {
  const { getCanonicalUrl } = storeToRefs(useGlobalsStore());
  const route = useRoute();
  const getAbsoluteUrl = (path?: string) => `${getCanonicalUrl.value}${path ?? ''}`;
  const { $i18n } = useNuxtApp();
  // Regular expression to remove the locale prefix from the path
  const localePrefixPattern = new RegExp(`^/${$i18n.locale.value}(?=/|$)`);
  const pathWithoutLocale = route.fullPath.replace(localePrefixPattern, '');
  // Constructing head link tags for canonical and alternate URLs
  const headLinks = [
    {
      rel: 'canonical',
      href: getAbsoluteUrl(route.fullPath),
    },
    ...$i18n.availableLocales.map((locale) => ({
      rel: 'alternate',
      href: getAbsoluteUrl(`${
        locale === $i18n.defaultLocale
          ? ''
          : `/${locale}`
        }${pathWithoutLocale}`),
      hreflang: locale,
    })),
  ];

  useHead({ link: headLinks });
  useSeoMeta({
    title,
    description,
    robots: 'index, follow',
  });
};
